.heading {
  max-width: 600px;
  margin: 0 auto;
  padding: 0px;
  border: 0px solid #ccc;
  border-radius: 5px;
}

.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

h1 {
  font-size: 2rem;
  text-align: center;
}

.search-container {
  display: flex;
  flex-direction: row;
}

.search-input {
  width: 100%;
  max-width: 500px;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 10px;
  font-size: 1rem;
}

.search-input:hover {
  outline: none;
}

.search-input:focus {
  outline: none;
}

.search-button {
  background-color: #005a8e;
  color: white;
  border-radius: 10px;
  padding: 14px 20px;
  margin: 8px 4px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}

.result {
  border: 10;
  border-radius: 20px;
  padding: 14px 20px;
  margin-top: 10px;
  font-size: 0.8rem;  
  background-color:#d8e8eb;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

